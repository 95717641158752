import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

var styles = {
  img: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
  },
  player: {
    songTitle: {
      display: "inline",
    },
  },
}

const Graphics = () => (
  <Layout>
    <SEO title="Graphics" />
    <h1>Graphics</h1>
  </Layout>
)

export default Graphics
